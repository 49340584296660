import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"


import Layout from "../components/layout"
import SEO from "../components/seo"

const PageHeader = styled.h1`
  margin-bottom: 50px;
  letter-spacing: -3px;
  line-height: 40px;
`
const SubHeadline = styled.h2`
  margin-bottom: 2.5rem;
  letter-spacing: -1.66px;
  line-height: 3.5rem;
  text-transform: uppercase;
`

const Context = styled.p`
  margin-bottom: 3rem;
  em {
    opacity: 80%;
  }
`

const List = styled.ol`
    margin-bottom: 20px;
    li {
        margin-bottom:10px;
    }
`

const TermsOfUse = () => {


  return (
    <Layout>
      <SEO title="Terms of Use" />
      <div className="container mt-100">
        <div class="row">
          <div class="col-lg-8 offset-lg-2 terms-section">
                <div>
                    <PageHeader>TERMS &amp; CONDITIONS OF USE</PageHeader>
	<SubHeadline>BINDING AGREEMENT</SubHeadline>
	<Context>By accessing all or any part of this Site of The CementWorks, LLC d/b/a The Bloc (hereinafter referred to as "Bloc" or "we"), you agree to be bound by these Terms &amp; Conditions (the "Terms of Use") and the Privacy Policy posted on this Site. If you do not agree to be bound by these Terms of Use and the Privacy Policy, you should not access or view this Site. In addition, this Site is intended only for users who are at least 18 years old and by accessing this Site, you warrant that you are at least 18 years old.</Context>
	<SubHeadline>AMENDMENT AND UPDATES TO TERMS &amp; CONDITIONS</SubHeadline>
	<Context><strong>Each time a user accesses and views the Site, the Terms of Use and the Privacy Policy should be reviewed because The Bloc reserves the right to change them from time to time without notice. Your continued use of the Site immediately after such changes are posted on the Site constitutes your acceptance of such change.</strong></Context>
	<SubHeadline>UPDATES AND MODIFICATIONS</SubHeadline>
	<Context>Content and materials on this Site may be changed, removed, or updated at any time without notice. While The Bloc uses reasonable efforts to maintain the accuracy of the content and information on this Site, the possibility exists that some materials could include errors, inaccuracies, or out-of-date information, and The Bloc is under no obligation to update such material.</Context>
	<SubHeadline>OWNERSHIP OF CONTENT AND MARKS; TRADEMARKS AND COPYRIGHTS; LIMITATIONS</SubHeadline>
	<Context>The contents of this Site are copyrighted by The Bloc or third-party licensors or providers. All rights are reserved. All information, materials, contents, publications, services, text, pictures, photographs, audio, video, graphics, logos, marks, displays, images, applications, and software and the design, selection, and arrangement thereof (the "Content") are owned or licensed by The Bloc, its licensors, or other providers of such material and are protected by US and international copyright, trademark, patent, trade secret, and other intellectual property or other proprietary rights laws. You agree that you have no rights with respect to the Content, except the limited and revocable rights provided by the Site to access and use the Content as provided in these Terms &amp; Use. The Bloc grants you a limited license and permission to access and make personal use of the Site but not to download any portion of it for any commercial purpose or to modify any portion of it. Except as explicitly provided in these Terms of Use, you agree not to store in any form, distribute, transmit, display, reproduce, modify, create derivative works from, sell, or otherwise exploit any of the Content or any portion of the Site for any commercial purpose. You may display and occasionally print a single copy of pages of the Site for your personal, noncommercial use, but you may not otherwise reproduce any of the Content or material appearing on the Site without The Bloc's prior written consent, which may be withheld in The Bloc's sole discretion.</Context>
	<Context>All trademarks, service marks, related names, logos, trade dress, trade names, product and service names, designs, and slogans (collectively, the "Marks"), including but not limited to those Marks displayed or used on the Site, are registered or common law trademarks of The Bloc or its affiliates or licensors and are protected by US and international trademark laws. Nothing contained on this Site or in these Terms of Use grants you any right to use, misuse, copy, reproduce, download, transmit, modify, or distribute any of the Marks without the prior written permission of The Bloc or that of the respective owner(s). The Bloc reserves the right to protect its Marks to the fullest extent of the law if prior written permission is not obtained. Moreover, the Marks may not be used in connection with any product or service in any manner that is likely to cause confusion among customers or in any manner that disparages or discredits The Bloc. The names of all other products and services mentioned on this Site may be trademarks or service marks of their respective owners.</Context>
	<Context>You shall not use or attempt to use any "scraper," "robot," "bit," "Spider," "data mining," code, or any other automated device, program, tool, algorithm, process, or methodology to access, acquire, copy, or monitor any portion of the Site or any of the Content, without the express written consent of The Bloc.</Context>
	<Context>You are prohibited from using any of the Content to harass, advertise, solicit, contact, or sell to any individual or entity.</Context>
	<SubHeadline>LINKS TO THIS SITE AND LINKS FROM THIS SITE</SubHeadline>
	<Context>Without the express written consent of The Bloc, no other site has the right to link to or frame to this Site.</Context>
	<Context><strong>There may be links on this Site to third-party sites. When you link to a third party's site, The Bloc is not responsible or liable for any content or services provided on such third party's site or for the use of your information by such third party or its site.</strong></Context>
	<SubHeadline>RESTRICTIONS ON YOUR USE OF THE SITE</SubHeadline>
	<Context>You may use the Site only for lawful purposes and in accordance with these Terms of Use. You agree to use this Site in compliance with all applicable laws, rules, and regulations in a manner that does not, in The Bloc's sole judgment, negatively reflect on the good will or reputation of The Bloc or any of its affiliates.</Context>
	<SubHeadline>DISCLAIMERS</SubHeadline>
	<Context>THIS SITE AND THE CONTENT, SERVICES, AND MATERIALS PROVIDED ON OR THROUGH USE OF THIS SITE ARE PROVIDED "AS IS" WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OF ANY KIND. ALL CONTENT, SERVICES, AND MATERIALS ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE BLOC DISCLAIMS ALL WARRANTIES AND REPRESENTATIONS WITH RESPECT TO THIS SITE AND THE CONTENT, SERVICES, AND MATERIALS PROVIDED ON OR THROUGH USE OF THIS SITE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, NONINFRINGEMENT OF INTELLECTUAL PROPERTY, AND FITNESS FOR ANY PARTICULAR PURPOSE. THE BLOC MAKES NO WARRANTIES, REPRESENTATION, OR GUARANTEES OF ANY KIND WITH RESPECT TO THE CONTENT, SERVICES, AND MATERIALS PROVIDED ON OR THROUGH THE SITE, INCLUDING, WITHOUT LIMITATION, NO WARRANTIES ABOUT THE CORRECTNESS, ACCURACY, OR TIMELINESS OF ANY CONTENT. THE BLOC MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE CONTENT, SERVICES, OR MATERIALS PROVIDED ON OR THROUGH USE OF THE SITE. THE BLOC DOES NOT GUARANTEE THAT THIS SITE OR THE CONTENT, SERVICES, OR MATERIALS PROVIDED ON OR THROUGH USE OF THE SITE WILL BE ACCESSIBLE, UNINTERRUPTED, WITHOUT DELAY, ERROR, OR OMISSION OR FREE OF VIRUSES OR HARMFUL CODE. YOU ASSUME ALL RISKS ASSOCIATED WITH THE USE AND OPERATION OF THE SITE AND ALL CONTENT, SERVICES, AND MATERIALS PROVIDED ON OR THROUGH USE OF THIS SITE.</Context>
	<SubHeadline>LIMITATION OF LIABILITY</SubHeadline>
	<Context>IN NO EVENT SHALL THE BLOC, ITS AFFILIATES, AND ITS AND THEIR OFFICERS, DIRECTORS, TRUSTEES, AGENTS, REPRESENTATIVES, VOLUNTEERS, AND EMPLOYEES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, CONSEQUENTIAL, OR OTHER DAMAGES WHATSOEVER (INCLUDING, BUT NOT LIMITED TO, LIABILITY FOR LOSS OF PROFITS OR COST OF SUBSTITUTE GOODS) IRRESPECTIVE OF THE FORM OF ANY ACTION, INCLUDING, BUT NOT LIMITED TO, CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTIONS ARISING OUT OF OR IN CONNECTION WITH THE SITE OR THE CONTENT, THE SERVICES, OR THE MATERIALS PROVIDED ON OR THROUGH USE OF SITE. IN THE EVENT THAT THE BLOC, OR ANY OF ITS AFFILIATES OR ANY OF ITS OR THEIR OFFICERS, DIRECTORS, TRUSTEES, AGENTS, REPRESENTATIVES, OR EMPLOYEES IS DETERMINED TO HAVE ANY LIABILITY WITH RESPECT TO THE SITE OR THE CONTENT, SERVICES, OR MATERIALS PROVIDED ON OR THROUGH USE OF THE SITE, THE BLOC'S AND ITS AFFILIATES', ITS AND THEIR OFFICERS', DIRECTORS', MEMBERS', MANAGERS', AGENTS', REPRESENTATIVES', AND EMPLOYEES' AGGREGATE LIABILITY TO ANY AND ALL USERS OF THE SITE OR ANY OF THE CONTENT, SERVICES, APPLICATIONS, SOFTWARE, OR MATERIALS PROVIDED ON OR THROUGH USE OF THE SITE SHALL NOT EXCEED FIVE DOLLARS ($5.00).</Context>
	<SubHeadline>INFRINGEMENT POLICY</SubHeadline>
	<Context>If you believe that any content or materials on the Site infringes upon any copyright or intellectual property right that you own or control, you must submit a written notice to the designated agent set forth below that complies with the requirements below and is delivered to our designated agent to receive notification of claimed infringement:</Context>
	<Context>Wai-Man Leung
        <br/>The CementWorks, LLC d/b/a The Bloc
		<br/>32 Old Slip, 15th Floor
		<br/>New York, NY 10005
    </Context>
	<Context>Phone: 212-524-6200
		<br/>Fax: 212-524-6299
		<br/>Email: <a href="mailto:info@thebloc.com">info@thebloc.com</a></Context>
	<Context>The Digital Millennium Copyright Act requires that such notification be in writing and include all of the following information:</Context>
	<List class="c">
		<li>A physical or electronic signature of a person authorized to act on behalf of (1) the owner of an exclusive right that is allegedly infringed or (2) the person defamed.</li>
		<li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at the Site. Similarly, for materials that are defamatory or infringe patent, trademark, or other proprietary rights of a third party, please submit a list of such materials.</li>
		<li>Identification of the material that is claimed to be infringing, to be the subject of infringing activity, or that is claimed to be defamatory and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material.</li>
		<li>Information reasonably sufficient to permit us to contact you, such as your address, telephone number, and/or electronic mail address.</li>
		<li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright or other proprietary right owner, its agent, or the law.</li>
		<li>A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed or on behalf of the person defamed.</li>
	</List>
	<SubHeadline>PRIVACY POLICY</SubHeadline>
	<Context>All information The Bloc collects on this Site is subject to The Bloc's <Link to="/privacypolicy">Privacy Policy</Link>. By using the Site, you consent to all actions taken by The Bloc with respect to your information in compliance with the Privacy Policy and you agree to the terms of the Privacy Policy.</Context>
	<Context><strong>There may be links on this Site to third-party sites. When you link to a third party's site, all information collected by such third party is subject to such third party's privacy policy and not to The Bloc's Privacy Policy.</strong></Context>
	<SubHeadline>APPLICABLE LAWS, JURISDICTION, AND WAIVERS</SubHeadline>
	<Context>The Site is provided by The Bloc from its offices in the United States of America. The Bloc makes no representation as to the suitability of the materials on the Site for use outside of the United States of America. Any claim relating to this Site or the Content or any services, applications, or material on the Site, including your use of the same, shall be governed by the laws of the State of New York, excluding its principles of conflicts of laws and with respect to copyrights, patents, and trademark rights, the federal laws of the United States. In the event of any claim or dispute arising out of or relating to these Terms of Use, you agree to the exclusive jurisdiction in the courts located in New York, New York and waive any rights to claim inconvenient forum or immunity, except that with respect to any injunctive action brought by The Bloc against you, The Bloc also has the right to bring an action against you in any court that has jurisdiction. Each user of the Site waives any and all rights to a jury trial with respect to any claim arising out of or relating to the Site or any Content, services, or materials provided in or through the Site.</Context>
	<SubHeadline>INDEMNITY</SubHeadline>
	<Context>You agree to indemnify and hold harmless The Bloc, its affiliates, and our and their affiliates' respective officers, directors, members, managers, and employees (individually and collectively, the "Indemnified Parties") from and against any and all liabilities, damages, costs, losses, claims, demands, and expenses (including, without limitation, reasonable attorney's fees) that any or all of the Indemnified Parties may incur arising out of or relating to (i) your use of this Site or the information provided on the Site; (ii) your use of the goods or services offered on the Site; and/or (iii) any breach by you of any of the terms contained in these Terms of Use or the Privacy Policy, including, without limitation, any claims alleging facts that, if true, would constitute a breach by you of the Terms of Use or the Privacy Policy. This indemnity is in addition to, and not in lieu of, any other indemnity obligations that may be stated herein.</Context>
	<SubHeadline>GENERAL</SubHeadline>
	<Context>These Terms of Use, together with the <Link to="/privacypolicy">Privacy Policy</Link>, constitutes the entire agreement between The Bloc and all users of this Site with respect to the subject matter contained in these Terms of Use and supersede all previous and contemporaneous agreements, proposals, and communications, written or oral.</Context>
	<Context>If any provision of these Terms of Use shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability of any remaining provisions. No waiver of these terms will be valid, except in the event that the waiver is in writing and signed by an officer of The Bloc.</Context>
	<Context>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the maximum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</Context>
	<SubHeadline>CONTACT THE BLOC</SubHeadline>
	<Context>If you have any questions about these Terms of Use, the practices of this Site, your dealings with the Site, or about the work of The Bloc, you may contact us at <a href="mailto:info@thebloc.com">info@thebloc.com</a> or by writing to us at:</Context>
	<Context>The CementWorks, LLC d/b/a The Bloc
		<br/>32 Old Slip, 15th Floor
		<br/>New York, NY 10005</Context>
	<SubHeadline>Effective Date</SubHeadline>
	<Context>The effective date of these Terms of Use is March 8, 2021.</Context>
                </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default TermsOfUse
